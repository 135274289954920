export default [
  {
    header: 'Készletkezelés',
  },
  {
    title: 'Kezdőlap',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Eladások',
    route: 'orders-index',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Termékkezelés',
    route: 'products-index',
    icon: 'ShoppingBagIcon',
  },
  // {
  //   title: 'Raktárkészlet',
  //   route: 'stock-index',
  //   icon: 'ArchiveIcon',
  // },
  {
    title: 'Kereskedelem',
    icon: 'DollarSignIcon',
    children: [
      // {
      //   title: 'Értékesítés',
      //   route: 'sale',
      //   icon: 'ArrowLeftIcon',
      // },
      {
        title: 'Bevételezés',
        route: 'inward-handling',
        icon: 'ArrowLeftIcon',
      },
      {
        title: 'Selejtezés',
        route: 'scrapping',
        icon: 'ArrowLeftIcon',
      },
      {
        title: 'Raktárközi mozgatás',
        route: 'transfer',
        icon: 'ArrowRightIcon',
      },
    ],
  },

  {
    header: 'Szervíz',
  },
  {
    title: 'Raktárak',
    route: 'warehouses-index',
    icon: 'BoxIcon',
  },
  {
    title: 'Kategóriák',
    route: 'categories-index',
    icon: 'FolderIcon',
  },
  {
    title: 'Felhasználók kezelése',
    route: 'users-index',
    icon: 'UserCheckIcon',
  },
  {
    title: 'Kassza tevékenységek',
    route: 'cash-register-activities',
    icon: 'UserCheckIcon',
  },
  // {
  //   title: 'Beállítások',
  //   route: 'settings',
  //   icon: 'SettingsIcon',
  // },
]
